import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    className?: string,
}>;

const Plain: React.FC<Props> = (props: Props) => {
    return (
        <span className={`plain${props.className ? ' ' + props.className : ''}`}>
            {props.children}
        </span>
    );
};

export default Plain;
