import React from 'react';
import { createUseStyles } from 'react-jss';

import iPhoneAllBras500 from '../assets/iPhoneAllBras_500.png';
import iPhoneAllBrasTop from '../assets/iPhoneAllBrasTop.png';
import Column from '../atoms/Column';
import InnerFrame from '../atoms/InnerFrame';
import Row from '../atoms/Row';
import WordmarkWithSlogan from '../atoms/WordmarkWithSlogan';
import GoogleFormSignup from '../organisms/GoogleFormSignup';
import { ScreenSizeContext } from '../organisms/PageWrapper';
import Spacer from '../stylisticComponents/Spacer';

import './Home.css';

const useStyles = createUseStyles({
    home: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    leftColumn: {
        flex: 5,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: '100%',
    },
    rightColumn: {
        flex: 4,
        paddingLeft: '10vw',
        paddingRight: '.25rem',
        overflow: 'hidden',
        height: '100%',
        justifyContent: 'center',
    },
    screenshot: {
        maxWidth: '100%',
        height: 'auto', // Keeps the original aspect ratio
        position: 'relative', // Allows positioning adjustments
        //top: '5%', // Adjust this to control how much of the image is clipped at the bottom
    },
    screenshotOnMobile: {
        maxWidth: '100%', // Makes sure the image doesn't overflow
        height: 'auto', // Keeps the original aspect ratio
        position: 'relative', // Allows positioning adjustments
        top: '50%', // Adjust this to control how much of the image is clipped at the bottom
    },
    mobileImageContainer: {
        height: '25%', // only show the top 50% of the image
        overflow: 'hidden',
        position: 'relative',
    },
    desktopImageContainer: {
        height: '100%',
        display: 'grid',
        alignContent: 'center',
    },
    mobileScreenshot: {
        width: '100%',
        position: 'relative',
    },
    tabletScreenshot: {
        width: '70%',
        position: 'relative',
    },
    screenReaderOnly: {

    }
});



const Home = () => {
    const styleClasses = useStyles();

    return (
        <div className={styleClasses.home}>
            <h1 aria-label="Home page with Tester Signup" />
            <ScreenSizeContext.Consumer>
                {screenSize => (
                    <InnerFrame isMobile={screenSize.isTablet || screenSize.isMobile}>
                            {(screenSize.isTablet || screenSize.isMobile) ? (
                                <Column>
                                    <WordmarkWithSlogan isMobile={screenSize.isMobile} isTablet={screenSize.isTablet} />
                                    <Spacer />
                                    <Spacer />
                                    <div className={styleClasses.mobileImageContainer}>
                                        <img className={screenSize.isTablet ? styleClasses.tabletScreenshot : styleClasses.mobileScreenshot}
                                             src={iPhoneAllBrasTop}
                                             alt="All Bras list screenshotted on a Pixel 5" />
                                    </div>
                                    <Spacer />
                                    <GoogleFormSignup />
                                </Column>
                            ) : (
                                <Row>
                                    {/* Desktop Layout */}
                                    <Column className={styleClasses.leftColumn}>
                                        <WordmarkWithSlogan />
                                        <GoogleFormSignup />
                                    </Column>
                                    <Column className={styleClasses.rightColumn}>
                                        <div className={styleClasses.desktopImageContainer}>
                                            <img className={styleClasses.screenshot}
                                                 src={iPhoneAllBras500}
                                                 width={500}
                                                 height={934}
                                                 alt="All Bras list screenshotted on a Pixel 5" />
                                        </div>
                                    </Column>
                                </Row>
                            )}

                    </InnerFrame>
                )}
            </ScreenSizeContext.Consumer>
        </div>
    );
}

export default Home;
