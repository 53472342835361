import React from 'react';
import Bold from './Bold';
import Plain from './Plain';

type Props = {
    heading: string,
    subheading: string
};

const BoldAndPlainTwoLineHeading: React.FC<Props> = (props: Props) => {
    return (
        <p className="textHeading">
            <Bold>{props.heading}</Bold>
            <br />
            <Plain>{props.subheading}</Plain>
        </p>
    );
}

export default BoldAndPlainTwoLineHeading;
