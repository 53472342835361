import React from 'react';
import ScrollToTop from "react-scroll-to-top";

import Pane from '../atoms/Pane';
import BoldAndPlainTwoLineHeading
    from '../stylisticComponents/BoldAndPlainTwoLineHeading';
import BulletDot from '../stylisticComponents/BulletDot';
import BulletedParagraph from '../stylisticComponents/BulletedParagraph';
import MixedStyleParagraph from '../stylisticComponents/MixedStyleParagraph';
import Paragraph from '../stylisticComponents/Paragraph';
import Spacer from '../stylisticComponents/Spacer';

/**
 * Although the use of custom components is not really necessary here, we use them
 * so that this same file can be effectively copy-pasted between the website and
 * the app. Even though the simple stylistic components like Bold etc have different
 * implementations in each place, all that matters is that they exist and perform
 * the same function.
 *
 * By doing things this way, there is only one place where we need to manually
 * update text when policies change. That means there is only one place we need
 * to scrupulously check for textual errors before publishing.
 */
const TermsOfUse = () => {

    return (
        <Pane alignLeft={true}>
            <BoldAndPlainTwoLineHeading
                heading={
                'Cupbearer Mobile App and Site Terms of Use Agreement'
                }
                subheading={
                'Effective Date: August 30, 2023'
                }
            />
            <MixedStyleParagraph
                words={
                [
                    //0
                    'Welcome, and thank you for your interest in Cupbearer, LLC (',
                    //1
                    '“Cupbearer”',
                    //2
                    ', ',
                    //3
                    '“we”',
                    //4
                    ', ',
                    //5
                    '“us”',
                    //6
                    ', or ',
                    //7
                    '“our”',
                    //8
                    ').  Cupbearer provides a mobile application to help users conveniently find and keep track of their best bra type and size, and to provide related categorization, rating, recommendation, sizing, and education services.  This ',
                    //9
                    '“Cupbearer App and Site Terms of Use Agreement”',
                    //10
                    '(the ',
                    //11
                    '“Terms of Use”',
                    //12
                    'or ',
                    //13
                    '“Terms”',
                    //14
                    ') is applicable to our Mobile Application (“',
                    //15
                    'App',
                    //16
                    '”), our website (',
                    //17
                    'www.cupbearer.app',
                    //18
                    ') (the ',
                    //19
                    '“Site“',
                    //20
                    '), and any other websites or applications that are owned and operated by Cupbearer on which these Terms of Use are posted (collectively, the ',
                    //21
                    '“App“',
                    //22
                    ') and utilize or access our bra categorization, rating, recommendation, sizing, and education services (the ',
                    //23
                    '“Services“',
                    //24
                    ').'
                ]}
                boldIndices={[1,3,5,7,9,11,13,15,19,21,23]}
                webLinkIndices={[17]}
            />
            <MixedStyleParagraph
                words={[
                    //0
                    'We provide the ',
                    //1
                    'Cupbearer Mobile App and Site Privacy Policy',
                    //2
                    ' (',
                    //3
                    '“Privacy Policy”',
                    //4
                    ') to help you understand our privacy practices and make informed decisions when using our App and the Services.  ',
                    //5
                    'Our Privacy Policy is hereby incorporated by reference into these Terms of Use, which are binding on all App and Services users, and further governs your use of the App and the Services. The Privacy Policy can be found at: ',
                    //6
                    'https://cupbearer.app/privacy',
                    //7
                    ' on the Site, or at “Settings->System->Privacy Policy” on the App.'
                ]}
                boldIndices={[1,3,5,6,7]}
                webLinkIndices={[6]}
            />
            <MixedStyleParagraph
                words={[
                    //0
                    'These Terms of Use, together with the Privacy Policy, create a legal contract between you (referred to herein as ',
                    //1
                    '“you”',
                    //2
                    ', ',
                    //3
                    '“your”',
                    //4
                    ', and ',
                    //5
                    '“user”',
                    //6
                    ') and Cupbearer regarding your use of the App, the Site and the Services.  Collectively, the Terms of Use and Privacy Policy shall be referred to hereafter as the ',
                    //7
                    '“Agreement”',
                    //8
                    '.'
                ]}
                boldIndices={[1,3,5,7]}
            />
            <Paragraph text={
                'PLEASE READ THE FOLLOWING TERMS OF USE CAREFULLY. BY ACCESSING, BROWSING, OR USING THE APP, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS IN THIS AGREEMENT. IF YOU DO NOT AGREE WITH THESE TERMS, DO NOT ACCESS OR USE THE APP, SITE, OR SERVICES.'
            } />
            <MixedStyleParagraph
                words={[
                    //0
                    'THIS APP IS NOT DIRECTED TO CHILDREN.',
                    '  PLEASE NOTE THAT THESE TERMS INCLUDE A BINDING ARBITRATION PROVISION, INCLUDING A CLASS ACTION WAIVER. BY AGREEING TO BINDING ARBITRATION, TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, YOU WAIVE YOUR RIGHT TO LITIGATE DISPUTES THROUGH A COURT AND TO HAVE A JUDGE OR JURY DECIDE YOUR CLAIMS.'
                ]}
                boldIndices={[0]}
                />
            <Spacer />
            <BulletedParagraph
                bullet={'1.'}
                paragraph={
                <MixedStyleParagraph
                    topIsFlush={true}
                    words={
                    [
                        'Description of App.',
                        ' Cupbearer operates the App to enable users to gather and store your own personal information about individual bras that you voluntarily enter in the App such as brand, model, size, fit, your ratings and tags regarding types or features of specific bras to help you categorize the bras in your account; the App may also make bra recommendations regarding the user\'s best-fitting bra and suggestions for bra brands and models that may match the user’s preferences and recommendations for further bra fit education.'
                    ]}
                    boldIndices={[0]} />
                } />
            <BulletedParagraph
                bullet={'2.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Modification of Terms.',
                                ' Cupbearer reserves the right, in its sole discretion, to change, modify, add, remove, or otherwise alter the Terms at any time. We will make reasonable effort to provide notice to you of such modifications, such as by posting a notice on our homepage for a reasonable period of time and changing the “Effective Date” above, and such amended terms will be effective against you when we have posted such notice and the effective date. However, changes made for legal reasons will be effective immediately. Accordingly, you should review the App and these Terms regularly to keep yourself apprised of any future changes. Your use of the App following any modifications will be deemed to establish your agreement with such new terms and conditions.'
                            ]}
                        boldIndices={[0]} />
                } />
            <BulletedParagraph
                bullet={'3.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'App Access License.',
                                ' Cupbearer grants you a limited, revocable, non-exclusive, non-transferable license to access and make personal, noncommercial use of the App, and not to modify or decompile any portion of the App. This license does not include: any unauthorized commercial or non-commercial use of the App; any derivative use of the App; or any use of data mining, robots, or similar data gathering and extraction tools. The App and/or any portion of the App may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any purpose, whether commercial or non-commercial, without Cupbearer’s express prior written consent. You shall not use any meta tags or any other “hidden text” utilizing our name or trademarks without our express prior written consent. Any unauthorized use automatically terminates the permissions and/or licenses granted by us to you.'
                            ]}
                        boldIndices={[0]} />
                } />
            <BulletedParagraph
                bullet={'4.'}
                paragraph={
                    <React.Fragment>
                        <MixedStyleParagraph
                            topIsFlush={true}
                            words={
                                [
                                    'Prohibited Conduct.',
                                    ' You are expressly prohibited from any conduct that: (1) threatens the security, integrity, or availability of the App; (2) provides or facilitates access to the App by unauthorized users or services; and/or (3) results in prohibited duplication, transmission, or exposure of the App (as defined below). You shall not use the App in violation of these Terms, or any applicable local, state, national, or international law or regulation. Nor shall you use the App in a manner which threatens the security, stability, or integrity of the App or networks connected to the App ("Service Network"). Without limiting the foregoing, you acknowledge and agree that you will not take any action to:'
                                ]}
                            boldIndices={[0]} />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'use or attempt to use any "deep-link," "scraper," "robot," "bot," "spider," "data mining," "computer code," or any other automated device, program, tool, algorithm, process or methodology or manual process having similar processes or functionality, to access, acquire, copy, or monitor any portion of the App, any data or content found on or accessed through the App;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'introduce viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'attack our App via a denial-of-service attack or a distributed denial-of service attack (a breach of this provision would commit a criminal offense and we will report any such breach to the relevant law enforcement authority(ies) and we will cooperate with those authorities by disclosing your identity to them, and in the event of such a breach, your right to use the App will cease immediately);'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'violate any measure employed to limit or prevent access to the App or otherwise obtain or attempt to obtain through any means any content, functionality, or other information which has not been intentionally made available to you either by visible display on the App or access through a visible link on the App;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'decompile, reverse engineer, or otherwise attempt to obtain the source code of the App;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'attempt, in any manner, to gain unauthorized access to the Service Network, attempt to gain unauthorized access to our App, the server on which our Sites are stored, or any server, computer or database connected to our Service Network, or otherwise violate the security of the Service Network or access encrypted codes;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'interfere with or disrupt (or attempt to interfere with or disrupt) the proper working of the App or Service Network, or violate any requirements, procedures, policies or regulations of the Service Network;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'take or attempt any action that, in the sole and absolute discretion of Cupbearer, imposes or may impose an unreasonable or disproportionately large load or burden on the Service Network, disrupts the normal flow of data, or threatens the stability of the App or Service Network; or'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'engage in any conduct which, in Cupbearer’s sole and absolute discretion, diminishes the pedagogical or commercial value of the App, infringes any proprietary rights in the App, or otherwise violates these Terms.'
                                    } />
                            } />
                    </React.Fragment>
                } />
            <BulletedParagraph
                bullet={'5.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'Communications.',
                                //1
                                '  Cupbearer may contact you via the contact information or through information you provide by initiating contact (e.g., through a “Sign up for updates” or “Contact Us” feature of the App). By providing such contact information, you give permission to receive communications from Cupbearer via that contact information. We may use your email address to send you information about the Cupbearer App, Site or Services.  You may opt-out of such email by using the “unsubscribe” link in the message, or by sending an email to: ',
                                //2
                                'unsubscribe@cupbearer.app',
                                //3
                                '. Please understand that your opt-out will apply to marketing communications and you will continue to receive communications from us related to your account, the App and the Site operations and security.'
                            ]}
                        boldIndices={[0]}
                        emailIndices={[2]}
                    />
                } />
            <BulletedParagraph
                bullet={'7.'}
                paragraph={
                <React.Fragment>
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'Intellectual Property.',
                                //1
                                ' “Intellectual Property Rights” for purposes of this Agreement means all intellectual property rights worldwide arising under statutory or common law, and whether or not perfected, including, without limitation, all (i) patents, patent applications, and patent rights; (ii) rights associated with works of authorship including copyrights, copyright applications, and copyright registrations; (iii) rights relating to the protection of trade secrets and Proprietary Information (defined below); (iv) any other proprietary rights relating to intangible property; (v) divisions, continuations, renewals, reissues and extensions of the foregoing (as and to the extent applicable) now existing, hereafter filed, issued or acquired; and (vi) all goodwill associated with any of the foregoing.',
                            ]}
                        boldIndices={[0]}
                    />
                    <Paragraph text={
                        'All right, title, and interest in and to the App and Intellectual Property Rights, are and will remain exclusively with Cupbearer. You acknowledge and agree that the Apps contain proprietary and confidential Information (“Proprietary Information”) including but not limited to text, graphics, logos, icons, images, and the arrangement and compilation of such content, computer programs, documentation, and information of or containing proprietary information relating to the computing programs, is protected by applicable U.S. and/or international copyright, patent, and/or trade secret laws and other laws. Regarding computer programs such as those that are part of the App, you expressly agree that you will not decompile, disassemble, or otherwise reverse engineer such computer programs or cause or enable others to do so. Except as expressly permitted by Cupbearer, you agree not to copy, modify, rent, lease, loan, sell, distribute, repost, publicly display, use for any commercial purposes, or create derivative works based on the App, in whole or in part, without the prior express written permission of Cupbearer.'
                    } />
                    <MixedStyleParagraph
                        words={
                            [
                                'Trademarks',
                                '. Nothing in these Terms establishes a license for you to use Cupbearer’s source identifiers, including its brands, marks, or logos, without the prior written consent of Cupbearer.'
                            ]}
                        underlineIndices={[0]}
                    />
                    <MixedStyleParagraph
                        words={
                            [
                                'Copyright',
                                '. All content and software code in the App, Site(s) and/or Services are the Copyrighted materials of Cupbearer, LLC.  2023 © Cupbearer, LLC.  Nothing in these Terms establishes a license for you to use any of Cupbearer’s Copyright materials, including source code, written content, visual or photographic content provided by Cupbearer or other copyrightable design elements without the prior written consent of Cupbearer.'
                            ]}
                        underlineIndices={[0]}
                    />
                    <MixedStyleParagraph
                        words={
                            [
                                'All copyrightable material which is provided by or uploaded to the App by you, such as personal notes, personal measurements and personal photos, are and shall remain your copyrighted material.  By uploading any material subject to copyright, you represent that you are the owner of such copyrighted material with full authority to grant to Cupbearer, LLC a perpetual, revocable license to store such materials on your behalf for use in the App, and for no other purpose, and that you hereby grant Cupbearer that license.'
                            ]}
                        underlineIndices={[0]}
                    />
                </React.Fragment>
                } />
            <BulletedParagraph
                bullet={'7.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'Third-Party Apps, Products, and Services; Links.',
                                //1
                                ' Any links to third-party websites do not constitute an endorsement by Cupbearer of the third-party website, products, or services. Your use of third-party websites and resources is at your own risk.',
                            ]}
                        boldIndices={[0]}
                    />
                } />
            <BulletedParagraph
                bullet={'8.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'Terms and Termination.',
                                //1
                                ' These Terms shall remain in full force and effect while you use the App unless your access to the App is terminated as provided in these Terms, in which case you no longer have the right to use the App. Cupbearer, in its sole discretion, may terminate your access and use of the App immediately at any time, for any reason, with no penalty, and at such time you will have no further right to use the App.',
                            ]}
                        boldIndices={[0]}
                    />
                } />
            <BulletedParagraph
                bullet={'9.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'Indemnity.',
                                //1
                                ' You agree, to the extent permissible by applicable law, to indemnify and hold Cupbearer and its subsidiaries, affiliates, officers, directors, agents, employees, as well as their successors and assigns, harmless from all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interests, awards, penalties, fines, costs, or expenses of any kind, including reasonable attorneys’ fees, made by any third party due to or arising from or in connection with your use of the App, or your breach of these Terms, including the foregoing representations, warranties, and covenants, or any term of any document it incorporates by reference, or your violation of any law, any contract or the rights of a third party, including without limitation, attorneys fees, and costs.',
                            ]}
                        boldIndices={[0]}
                    />
                } />
            <BulletedParagraph
                bullet={'10.'}
                paragraph={
                    <React.Fragment>
                        <MixedStyleParagraph
                            topIsFlush={true}
                            words={
                                [
                                    //0
                                    'DISCLAIMERS; NO WARRANTIES.',
                                    //1
                                    ' THE CUPBEARER APP AND ANY SOFTWARE, OR ANY OTHER PRODUCTS OR SERVICES MADE AVAILABLE TO YOU THROUGH THE APP ARE PROVIDED ON AN “AS IS”, “AS AVAILABLE,” AND WITH ALL FAULTS BASIS. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, CUPBEARER, LLC EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APP, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, QUALITY, AVAILABILITY, QUIET ENJOYMENT, FITNESS FOR A PARTICULAR PURPOSE, AND TITLE, NONINFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, CUPBEARER PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE APP WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATION, SYSTEM, OR SERVICES, OR COMPUTING DEVICE, OR OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM CUPBEARER, LLC OR THROUGH THE APP WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.',
                                ]}
                            boldIndices={[0]}
                        />
                        <Paragraph text={
                            'YOU UNDERSTAND AND AGREE THAT YOUR USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAINING OF CONTENT, WEBSITE MATERIALS, SOFTWARE, OR DATA THROUGH THE APP IS AT YOUR OWN DISCRETION AND RISK, AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM) OR LOSS OF DATA THAT RESULTS THEREFROM.'
                        } />
                        <Paragraph text={
                            'SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM STATE TO STATE AND JURISDICTION TO JURISDICTION.'
                        } />
                    </React.Fragment>
                } />
            <BulletedParagraph
                bullet={'11.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'LIMITATION OF LIABILITY AND DAMAGES.',
                                //1
                                ' EXCEPT AS SET FORTH HEREIN, CUPBEARER, LLC AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, AGENTS, EMPLOYEES, AS WELL AS THEIR SUCCESSORS AND ASSIGN, SHALL NOT BE LIABLE FOR ANY DAMAGES OR OTHER LIABILITY ARISING FROM NEGLIGENT ACTIONS OR INACTIONS ARISING FROM OR RELATED TO ANY LOSS DUE TO PERSONAL INJURY, PROPERTY DAMAGE, ANY LOSS OF PROFITS, SAVINGS, OR GOODWILL, LOSS OR INACCURACY OF DATA, OR ANY INDIRECT, PUNITIVE, EXEMPLARY, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, LOSS, COST, EXPENSE (WHETHER IN CONTRACT, TORT, INCLUDING NEGLIGENCE, PRODUCT LIABILITY, OR OTHERWISE) THAT RESULT FROM YOUR USE OF THE APP, INCLUDING BUT NOT LIMITED TO YOUR INABILITY TO USE THE APP FOR ANY REASON (E.G., ANY INTERRUPTION OF SERVICE, COMPUTER VIRUS) FOR USE OF THE APP, EVEN IF CUPBEARER OR AN AUTHORIZED REPRESENTATIVE OF CUPBEARER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR FOR ANY CLAIM BY ANY THIRD PARTY.',
                            ]}
                        boldIndices={[0]}
                    />
                } />
            <BulletedParagraph
                bullet={'12.'}
                paragraph={
                    <React.Fragment>
                        <MixedStyleParagraph
                            topIsFlush={true}
                            words={
                                [
                                    //0
                                    'Operation and Record Retention.',
                                    //1
                                    ' Cupbearer reserves the sole and total discretion with respect to decisions about the operation of the App and the Services. Cupbearer may, among other things, withdraw, suspend, or discontinue any functionality or feature of the App. Cupbearer is not responsible for transmission errors, or any corruption or compromise of data carried over local or interchange telecommunication carriers.  Except as specified above, Cupbearer is not responsible for maintaining data arising from or associated with the use of the App. Cupbearer reserves the right to maintain, delete or destroy all communications.',
                                ]}
                            boldIndices={[0]}
                        />
                        <MixedStyleParagraph
                            words={[
                                //0
                                'We will use, disclose, delete or retain your personal information only for as long as necessary to fulfill the purposes for which that personal information was collected and as permitted or required by applicable law.  Deletion requests will be honored within a reasonable timeframe and as required by applicable law. See our Privacy Policy (',
                                //1
                                'https://cupbearer.app/privacy',
                                //2
                                ') for more details.'
                                ]}
                            webLinkIndices={[1]}
                        />
                    </React.Fragment>
                } />
            <BulletedParagraph
                bullet={'13.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'Class Action Waiver.',
                                //1
                                ' YOU AND CUPBEARER AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provisions set forth above shall be deemed null and void in their entirety and the parties shall be deemed to have not agreed to arbitrate disputes.',
                            ]}
                        boldIndices={[0]}
                    />
                } />
            <BulletedParagraph
                bullet={'14.'}
                paragraph={
                    <React.Fragment>
                        <MixedStyleParagraph
                            topIsFlush={true}
                            words={
                                [
                                    //0
                                    'Dispute Resolution.',
                                    //1
                                    ' In the case of any dispute, you agree to make a good faith effort to resolve via informal resolution, which if unsuccessful, will be followed by binding arbitration in Seattle, King County, State of Washington unless otherwise agreed between the parties. The arbitration shall be conducted on a confidential basis pursuant to the Commercial Arbitration Rules of the American Arbitration Association.',
                                ]}
                            boldIndices={[0]}
                        />
                        <Paragraph text={
                            'Judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction, in accordance with Section 15, below.  The parties agree that any provision of applicable law notwithstanding, they will not request, and the arbitrator shall have no authority to award, punitive or exemplary damages against any party.'
                        } />
                    </React.Fragment>
                } />
            <BulletedParagraph
                bullet={'15.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'Governing Law and Venue.',
                                //1
                                ' These Terms shall be governed by and construed in accordance with the laws of the State of Washington, without regard to the choice of law provisions thereof.  Any legal suit, action, or proceeding arising out of or related to these Terms after the exhaustion of all efforts required by Section 15 may be instituted solely and exclusively in the state or federal courts located in Seattle, King County, State of Washington.  You hereby waive any and all objections to the exercise of jurisdiction over you by such courts and venue in such courts. Any cause of action or claim you may have with respect to the use of the App must be commenced within one (1) year after it arises, except to the extent such limitation is not enforceable. To the fullest extent permitted by law, each party to these Terms waives its or his or her right to a jury trial with respect to any dispute or other controversy arising from your use of or access to the App.',
                            ]}
                        boldIndices={[0]}
                    />
                } />

            <BulletedParagraph
                bullet={'16.'}
                paragraph={
                    <React.Fragment>
                        <MixedStyleParagraph
                            topIsFlush={true}
                            words={
                                [
                                    //0
                                    'Miscellaneous.',
                                ]}
                            boldIndices={[0]}
                        />
                        <BulletedParagraph
                            bullet={'A.'}
                            paragraph={
                            <MixedStyleParagraph
                                topIsFlush={true}
                                words={
                                [
                                    'Waiver',
                                    '. The failure of Cupbearer to exercise or enforce any right or provision of the Terms will not constitute a waiver of such right or provision. Any waiver of any provision of the Terms will be effective only if in writing and signed by Cupbearer.'
                                ]}
                                underlineIndices={[0]}
                            />}
                        />
                        <BulletedParagraph
                            bullet={'B.'}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={
                                        [
                                            'Severability',
                                            '. If any provision of the Terms is held to be unlawful, void, or for any reason unenforceable, then that provision will be limited or eliminated from the Terms to the minimum extent necessary and will not affect the validity and enforceability of any remaining provisions.'
                                        ]}
                                    underlineIndices={[0]}
                                />}
                        />
                        <BulletedParagraph
                            bullet={'C.'}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={
                                        [
                                            'Assignment',
                                            '. The Terms and any rights and licenses granted hereunder, may not be transferred or assigned by you without Cupbearer’s prior written consent, but may be assigned by Cupbearer without consent or any restriction. Any assignment attempted to be made in violation of the Terms shall be null and void.'
                                        ]}
                                    underlineIndices={[0]}
                                />}
                        />
                        <BulletedParagraph
                            bullet={'D.'}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={
                                        [
                                            'Survival',
                                            '. Upon termination of the Terms, any provision which, by its nature or express terms should survive, will survive such termination or expiration, including, but not limited to, Sections 2, 3, 5, 6, 8-16.'
                                        ]}
                                    underlineIndices={[0]}
                                />}
                        />
                        <BulletedParagraph
                            bullet={'E.'}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={
                                        [
                                            'Headings',
                                            '. The heading references herein are for convenience purposes only, do not constitute a part of the Terms, and will not be deemed to limit or affect any of the provisions hereof.'
                                        ]}
                                    underlineIndices={[0]}
                                />}
                        />
                        <BulletedParagraph
                            bullet={'F.'}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={
                                        [
                                            'Entire Agreement',
                                            '. The Terms and the Privacy Policy constitute the entire agreement between you and Cupbearer relating to the subject matter herein and will not be modified except in writing, signed by both parties, or by a change to the Terms or Privacy Policy made by Cupbearer as set forth herein.'
                                        ]}
                                    underlineIndices={[0]}
                                />}
                        />
                    </React.Fragment>
                } />
            <BulletedParagraph
                bullet={'17.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                //0
                                'Contact Information.',
                                //1
                                ' Questions or inquiries regarding these Terms should be directed to: ',
                                //2
                                'terms@cupbearer.app',
                                //3
                                '.'
                            ]}
                        boldIndices={[0]}
                        emailIndices={[2]}
                    />
                } />
            <Spacer />
            <ScrollToTop className="scrollToTop" />
        </Pane>
    );
}

export default TermsOfUse;
