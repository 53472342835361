import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState
} from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/cupbearerWithTrademark.svg';
import Footer from '../assets/Footer';
import CookieConsent from './CookieConsent';

const lightPurple = '#b868f8';
const darkerTeal = '#008080';
const alternateTealAccent = '#42ABAB';
const darkerLightPurple = '#6d21ae';

const useStyles = createUseStyles({
    '@font-face': [
        {
            fontFamily: 'OleoScriptSwashCaps',
            src: "local('OleoScriptSwashCaps'), url(./fonts/OleoScriptSwashCaps-Regular.ttf) format('truetype')",
        }, {
            fontFamily: 'OpenSans',
            src: "local('OpenSans'), url(./fonts/OpenSans-Regular.ttf) format('truetype')",
        },
    ],
    '@-webkit-keyframes SlowAnimation': {
        '0%': {backgroundPosition: '0% 50%'},
        '50%': {backgroundPosition: '100% 50%'},
        '100%': {backgroundPosition: '0% 50%'}
    },
    '@-moz-keyframes SlowAnimation': {
        '0%': {backgroundPosition: '0% 50%'},
        '50%': {backgroundPosition: '100% 50%'},
        '100%': {backgroundPosition: '0% 50%'}
    },
    '@keyframes backgroundGradient': {
        '0%': {backgroundPosition: '0% 50%'},
        '50%': {backgroundPosition: '100% 50%'},
        '100%': {backgroundPosition: '0% 50%'}
    },
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignContent: 'center',
        fontSize: '1.18rem',
        color: 'white',
        fontFamily: '"Open Sans", sans-serif',
        textAlign: 'center',
        background: `linear-gradient(200deg, ${darkerTeal}, ${lightPurple}, ${alternateTealAccent}, ${darkerLightPurple})`,
        backgroundSize: '400% 400%',
        '-webkit-animation': '$backgroundGradient 30s ease infinite',
        '-moz-animation': '$backgroundGradient 30s ease infinite',
        animation: '$backgroundGradient 30s ease infinite',
    },
    header: {
        fill: '#fff',
        stroke: '#fff',
        height: '5rem',
        marginTop: '1rem',
        maxWidth: '100%',
    },
});

type Props = PropsWithChildren<{
    hideHeader?: boolean;
}>;

function AutoScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]); // Dependency array includes pathname to trigger effect on route change

    return null; // This component does not render anything
}

export const ScreenSizeContext = createContext({
    isTablet: false,
    isMobile: false,
});

export function useScreenSize() {
    return useContext(ScreenSizeContext);
}

const PageWrapper: React.FC<Props> = (props: Props) => {
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 767 && window.innerWidth > 575);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 575);

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth < 768  && window.innerWidth > 575);
            setIsMobile(window.innerWidth <= 575);
        };
        window.addEventListener('resize', handleResize);
        // Call to set initial size
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [doCookieConsentReset, setDoCookieConsentReset] = useState<boolean>(false);

    const styleClasses = useStyles();

    return (
        <div className={`${styleClasses.pageWrapper}`}>
            <AutoScrollToTop />
            <CookieConsent doCookieConsentReset={doCookieConsentReset}
                           setDoCookieConsentReset={setDoCookieConsentReset} />
            {!props.hideHeader && (
                <Link to="/">
                    <Logo className={styleClasses.header} />
                </Link>
            )}
            <ScreenSizeContext.Provider value={{ isTablet, isMobile }}>
                {props.children}
                <Footer isTablet={isTablet} isMobile={isMobile}
                        setDoCookieConsentReset={setDoCookieConsentReset} />
            </ScreenSizeContext.Provider>

        </div>
    );
}

export default PageWrapper;
