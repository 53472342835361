import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
}>;

const Underline: React.FC<Props> = (props: Props) => {
    return (
        <span className="underline">
            {props.children}
        </span>
    );
};

export default Underline;
