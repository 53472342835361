import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import Pane from '../atoms/Pane';
import Bold from '../stylisticComponents/Bold';
import EmailLink from '../stylisticComponents/EmailLink';
import Plain from '../stylisticComponents/Plain';
import Spacer from '../stylisticComponents/Spacer';

const useStyles = createUseStyles({
    innerPane: {
        textAlign: 'left',
    },
    textLine: {
        marginTop: '.25rem',
    }
});

const Support = () => {
    const styleClasses = useStyles();

    return (
        <Pane className={styleClasses.innerPane}>
            <Bold>Questions About Account or Data Deletion?</Bold>
            <Plain className={styleClasses.textLine}>Learn more <Link to="/deletion">here</Link>.</Plain>
            <Spacer />

            <Bold>Other Questions? Need Help?</Bold>
            <Plain className={styleClasses.textLine}>We want to hear from you.</Plain>
           <Plain>Please reach out to <EmailLink email={'support@cupbearer.app'} /></Plain>
        </Pane>
    );
}

export default Support;
