import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

type Props = PropsWithChildren<{
    className?: string,
}>;

const useStyles = createUseStyles({
    spacer: {
        height: '3vh',
    }
});

const Spacer: React.FC<Props> = (props) => {
    const styleClasses = useStyles();

    return (
        <div className={`${styleClasses.spacer}${props.className ? ' ' + props.className : ''}`} />
    );
};

export default Spacer;
