import React from 'react';
import Bold from './Bold';

type Props = {
    bullet?: string,
    bulletComponent?: React.ReactNode,
    paragraph: React.ReactNode,
};

const BulletedParagraph: React.FC<Props> = (props: Props) => {
    return (
        <div className="bulletedParagraph">
            <div className="bulletColumn">{props.bullet ? <Bold>{props.bullet}</Bold> : props.bulletComponent}</div>
            <div className="paragraphColumn">{props.paragraph}</div>
        </div>
    );
}

export default BulletedParagraph;
