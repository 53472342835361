import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import DeletionInstructions from './templates/DeletionInstructions';
import GoogleForm from './templates/GoogleForm';

import Home from './templates/Home';
import './index.css';
import PageWrapper from './organisms/PageWrapper';
import PrivacyPolicy from './templates/PrivacyPolicy';
import Support from './templates/Support';
import TermsOfUse from './templates/TermsOfUse';
import NotFound from './templates/NotFound';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <BrowserRouter>
             <Routes>
                 <Route path='/' element={(
                     <PageWrapper hideHeader={true}>
                         <Home />
                     </PageWrapper>
                 )} />
                 <Route path='terms' element={(
                     <PageWrapper>
                         <TermsOfUse />
                     </PageWrapper>
                 )} />
                 <Route path='privacy' element={(
                     <PageWrapper>
                         <PrivacyPolicy />
                     </PageWrapper>
                 )} />
                 <Route path='support' element={(
                     <PageWrapper>
                         <Support />
                     </PageWrapper>
                 )} />
                 <Route path='deletion' element={(
                     <PageWrapper>
                         <DeletionInstructions />
                     </PageWrapper>
                 )} />
                 <Route path='signup' element={(
                     <PageWrapper>
                         <GoogleForm />
                     </PageWrapper>
                 )} />
                 <Route path='*' element={(
                     <PageWrapper>
                         <NotFound />
                     </PageWrapper>
                 )} />
             </Routes>
        </BrowserRouter>
    </React.StrictMode>
)
