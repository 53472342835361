import {
    faFacebook,
    faInstagram,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

import Column from '../atoms/Column';
import Row from '../atoms/Row';
import { openSansFontFamily } from '../globalConstants';

type Props = PropsWithChildren<{
    setDoCookieConsentReset: Dispatch<SetStateAction<boolean>>;
    isTablet: boolean;
    isMobile: boolean;
}>;

const useStyles = createUseStyles({
    '@global': {
        nav: {
            marginBottom: '.5rem',
        },
        'nav > * > * > *': {
            margin: '0 2ch',
        }
    },
    socialIcons: {
        marginLeft: '1ch',
        marginRight: '0',
    },
    cookiePrefsLink: {
        marginRight: '1ch'
    },
    copyrightFooter: {
        marginBottom: '1rem',
        fontFamily: openSansFontFamily,
        fontSize: '1.25rem',
        '& a': {
            color: 'white'
        },
        '& button': {
            color: 'white',
            fontFamily: openSansFontFamily,
            fontSize: '1.25rem',
            marginLeft: 0,
            marginRight: 0,
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    horizontalSpace: {
        width: '2ch',
    },
    menuList: {
        listStyleType: 'none',
    },
    linkRow: {
        marginBottom: '.5rem',
    },
    bottomRow: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoContainer: {
        display: 'inline-block',
        width: 'auto',
        height: '1.25rem',
    },
    igLogo: {
        fill: '#fff',
        stroke: '#fff',
        height: '100%',
        width: '100%',
        objectFit: 'contain',
    }
});

const Footer: React.FC<Props> = (props: Props) => {
    const styleClasses = useStyles();
    const currentYear = (new Date().getFullYear());
    const yearTxt = currentYear === 2022 ? "2022" : "2022 - " + currentYear;

    return (
        <footer className={styleClasses.copyrightFooter}>
            <nav>
                {(props.isTablet || props.isMobile) ? (
                    <Column>
                        <Link to="/terms">Terms of Use</Link>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/support">Support</Link>
                        <button onClick={() => {props.setDoCookieConsentReset(true)}}
                                onKeyPress={() => {props.setDoCookieConsentReset(true)}}
                        >Reset Cookie Preferences</button>
                        <Row className={styleClasses.bottomRow}>
                            <a className={styleClasses.socialIcons}
                               href="https://www.instagram.com/cupbearer_app/" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} size={'xl'} style={{color: "#ffffff",}} />
                            </a>
                            <a className={styleClasses.socialIcons}
                               href="https://www.facebook.com/cupbearerapp/" target="_blank">
                                <FontAwesomeIcon icon={faFacebook} size={'xl'} style={{color: "#ffffff",}} />
                            </a>
                            <a className={styleClasses.socialIcons}
                               href="https://www.linkedin.com/company/91455387/" target="_blank">
                                <FontAwesomeIcon icon={faLinkedin} size={'xl'} style={{color: "#ffffff",}} />
                            </a>
                        </Row>
                    </Column>
                ): (
                    <div>
                        <Row className={styleClasses.linkRow}>
                            <Link to="/terms">Terms of Use</Link>
                            <Link to="/privacy">Privacy Policy</Link>
                            <Link to="/support">Support</Link>
                        </Row>
                        <Row className={styleClasses.bottomRow}>
                            <button className={styleClasses.cookiePrefsLink}
                                    onClick={() => {props.setDoCookieConsentReset(true)}}
                                    onKeyPress={() => {props.setDoCookieConsentReset(true)}}
                            >Reset Cookie Preferences</button>
                            <a className={styleClasses.socialIcons}
                               href="https://www.instagram.com/cupbearer_app/" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} size={'xl'} style={{color: "#ffffff",}} />
                            </a>
                            <a className={styleClasses.socialIcons}
                               href="https://www.facebook.com/cupbearerapp/" target="_blank">
                                <FontAwesomeIcon icon={faFacebook} size={'xl'} style={{color: "#ffffff",}} />
                            </a>
                            <a className={styleClasses.socialIcons}
                               href="https://www.linkedin.com/company/91455387/" target="_blank">
                                <FontAwesomeIcon icon={faLinkedin} size={'xl'} style={{color: "#ffffff",}} />
                            </a>
                        </Row>

                    </div>
                    )}
            </nav>
            {(props.isTablet || props.isMobile) ? (
                <Column>
                    <span>© { yearTxt } - Cupbearer, LLC</span>
                    <a href="mailto:info@cupbearer.app">info@cupbearer.app</a>
                </Column>
            ) : (
                <Row>
                    © { yearTxt } - Cupbearer, LLC
                    <span className={styleClasses.horizontalSpace} />
                    <a href="mailto:info@cupbearer.app">info@cupbearer.app</a>
                </Row>
            )}
        </footer>
    );
}

export default Footer;
