import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import './Atoms.css';

const useStyles = createUseStyles({
    frame: {
        display: 'flex',
        flexDirection: 'column',
        border: '3px solid white',
        paddingTop: '2vw',
        paddingLeft: '3vw',
        paddingRight: '3vw',
        minWidth: '70vw',
        maxWidth: '70vw',
        minHeight: '40vw',
        marginTop: '5vw',
        marginBottom: '2vw',
        marginLeft: '6vw',
        marginRight: '6vw',
        "@media (max-width: 500px)": {
            display: 'block',

        }
    }
});

type Props = PropsWithChildren<{
    isMobile?: boolean,
}>;

const InnerFrame: React.FC<Props> = (props) => {
    const classStyles = useStyles();

    return (
        <div className={classStyles.frame}
             style={props.isMobile ? {flex: 'content', overflow: 'hidden'} : {}}>
            {props.children}
        </div>
    );
};

export default InnerFrame;
