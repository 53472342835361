import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router';
import Pane from '../atoms/Pane';
import Bold from '../stylisticComponents/Bold';
import Plain from '../stylisticComponents/Plain';
import Spacer from '../stylisticComponents/Spacer';

type Props = PropsWithChildren<{}>;

const useStyles = createUseStyles({
    testerSignup: {
        display: 'contents',
    },
    heading: {
        textTransform: 'uppercase',
        fontSize: '1.1rem',
        "@media (min-width: 1500px)": {
            fontSize: '1.8rem',
        },
        "@media (max-width: 500px)": {
            textAlign: 'center',
        }
    },
    spacer: {},
    description: {
        fontSize: '1rem',
        marginTop: '.5vh',
        "@media (min-width: 1500px)": {
            fontSize: '1.7rem',
        },
        "@media (max-width: 500px)": {
            textAlign: 'center',
        }
    },
    submitButton: {
        backgroundColor: '#DBECEA',
        color: 'black',
        fontWeight: 'bold',
        padding: '0.5rem 1rem',
        border: 'none',
        cursor: 'pointer',
        fontSize: '1.1rem',
        "@media (min-width: 1500px)": {
            fontSize: '1.8rem',
        },
        borderRadius: 10,
        boxShadow: 'rgba(0, 0, 0, 0.2) 1.95px 1.95px 2.6px',
        transition: 'all 0.3s ease', // Smooth transition
        '&:active': { // Active state
            transform: 'translateY(2px)', // Slight downward shift
            boxShadow: '1px 1px 1.3px rgba(0, 0, 0, 0.1)', // Reduce shadow
            backgroundColor: '#008080',
            color: 'white',
        },
    },
    outerText: {
        textAlign: 'left',
        fontSize: '1.2rem',
        "@media (min-width: 1500px)": {
            fontSize: '1.9rem',
        },
        "@media (max-width: 500px)": {
            textAlign: 'center',
        }
    }
});

const GoogleFormSignup: React.FC<Props> = (props: Props) => {
    const styleClasses = useStyles();
    const navigator = useNavigate();

    return (
        <div className={styleClasses.testerSignup}>
            <div className={styleClasses.outerText}>
                <Spacer />
                <Bold>
                    Cupbearer is coming on Android and iOS to revolutionize the way you relate to your bras.
                </Bold>
                <Pane useDropShadow={true} alignLeft={true}>

                    <Bold className={styleClasses.heading}>Want Early
                        Access?</Bold>

                    <Plain className={styleClasses.description}>
                        As an early adopter, discover a better bra experience
                        while helping us improve our features.
                    </Plain>
                    <Spacer />
                    <button className={styleClasses.submitButton}
                            aria-label="Yes I want to be more comfortable, opens a new tab"
                            onClick={() => navigator('/signup')} >
                        Yes, I want to be more comfortable
                    </button>
                </Pane>
            </div>
        </div>
    );
}

export default GoogleFormSignup;
