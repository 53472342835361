import React from 'react';
import Pane from '../atoms/Pane';
import ScrollToTop from "react-scroll-to-top";
import BoldAndPlainTwoLineHeading
    from '../stylisticComponents/BoldAndPlainTwoLineHeading';
import BulletDot from '../stylisticComponents/BulletDot';
import BulletedParagraph from '../stylisticComponents/BulletedParagraph';
import MixedStyleParagraph from '../stylisticComponents/MixedStyleParagraph';
import Paragraph from '../stylisticComponents/Paragraph';
import Spacer from '../stylisticComponents/Spacer';

/**
 * Although the use of custom components is not really necessary here, we use them
 * so that this same file can be effectively copy-pasted between the website and
 * the app. Even though the simple stylistic components like Bold etc have different
 * implementations in each place, all that matters is that they exist and perform
 * the same function.
 *
 * By doing things this way, there is only one place where we need to manually
 * update text when policies change. That means there is only one place we need
 * to scrupulously check for textual errors before publishing.
 */
const PrivacyPolicy = () => {
    return (
        <Pane alignLeft={true}>
            <BoldAndPlainTwoLineHeading
                heading={
                    'Cupbearer Mobile App and Site Privacy Policy'
                }
                subheading={
                    'Effective Date: August 30, 2023'
                }
            />
            <Spacer />
            <BulletedParagraph
                bullet={'1.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Privacy Policy Overview.',
                            ]}
                        boldIndices={[0]} />
                    }
            />
            <MixedStyleParagraph
                topIsFlush={true}
                words={
                    [
                        //0
                        'Cupbearer, LLC (“',
                        //1
                        'Cupbearer'
                        //2
                        ,'”, “',
                        //3
                        'we',
                        //4
                        '”, “',
                        //5
                        'us',
                        //6
                        '”, “'
                        //7
                        ,'our',
                        //8
                        '”) is a mobile application to help users conveniently find and keep track of their best bra type and size, and to provide related categorization, rating, recommendation, sizing, and education services. Cupbearer is committed to your understanding of our privacy practices. We have developed this Privacy Policy to explain how we collect, process, use, share, retain, secure, and transfer your personal information when you visit our mobile application (“'
                        //9
                        ,'App',
                        //10
                        '”) and/or our website (',
                        //11
                        'www.cupbearer.app',
                        //12
                        ') (the “',
                        //13
                        'Site',
                        //14
                        '”) and any other websites and applications that are owned and operated by Cupbearer on which this Privacy Policy is posted (collectively, the “',
                        //15
                        'App',
                        //16
                        '”) and utilize or access our bra categorization, rating, recommendation, sizing, and education services (the “',
                        //17
                        'Services',
                        //18
                        '”).  This Privacy Policy applies to any users of and visitors to the App, who are referred to herein as “',
                        //19
                        'you',
                        //20
                        '”, “',
                        //21
                        'your',
                        //22
                        '” or “',
                        //23
                        'user',
                        //24
                        '”.',
                    ]}
                boldIndices={[1,3,5,7,9,13,15,17,19,21,23]}
                webLinkIndices={[11]} />
            <MixedStyleParagraph
                words={
                    [
                        //0
                        'We are providing this Privacy Policy to help you understand our privacy practices and make informed decisions when using our App and the Services. ',
                        //1
                        'This Privacy Policy is incorporated by reference into the “Cupbearer Terms of Use Agreement” (“Agreement” or “Terms”) which is binding on all App users, and governs your use of the App. The Terms can be found at: ',
                        //2
                        'https://cupbearer.app/terms',
                        //3
                        ' on the Site, or at “Settings->System->Terms of Use” on the App.'
                        //4
                        ,' When you access our App, you acknowledge that you have read this Privacy Policy and understand and agree to its contents. You also acknowledge that this App is not directed to children.',
                    ]}
                boldIndices={[1,2,3]}
                webLinkIndices={[2]}
            />

            <Spacer />
            <BulletedParagraph
                bullet={'2.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Personal Information we collect.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <MixedStyleParagraph
                topIsFlush={true}
                words={
                    [
                        //0
                        'Cupbearer collects (i) personal information voluntarily supplied by you when you visit our App, and (ii) automatically collected information that is collected as you use the App. Personal information when referred to in this Privacy Policy is any information that can be reasonably linked to an identifiable individual such as name, postal address, telephone number, email address, credit and/or debit card number, purchase information, bra size, user measurements, and digital photos (“',
                        //1
                        'personal information',
                        //2
                        '”).',
                    ]}
                boldIndices={[1]}
            />
            <Paragraph text={
                'We strongly urge you to be careful about any personal information you voluntarily provide on the App or by using the Services. DO NOT DISCLOSE ANY SENSITIVE PERSONAL INFORMATION SUCH AS RACE, ETHNICITY, SEXUAL ORIENTATION, HEALTH CONDITIONS, OR PREGNANCY INFORMATION ON THE APP.'
            } />
            <Paragraph text={
                'Cupbearer collects the following personal information from and about you:'
            } />
            <BulletedParagraph
                bullet={''}
                paragraph={
                <React.Fragment>
                    <BulletedParagraph
                        bulletComponent={<BulletDot />}
                        paragraph={
                            <Paragraph
                                topIsFlush={true}
                                text={
                                    'Contact information: your email address if you create an account or if you include an email address when submitting feedback to us or filling out a form;'
                                } />
                        } />
                    <BulletedParagraph
                        bulletComponent={<BulletDot />}
                        paragraph={
                            <Paragraph
                                topIsFlush={true}
                                text={
                                    'Your bra information: information about your bras that you voluntarily enter in the App such as brand, model, size, your ratings and any tags about types or features of your bras to help you categorize your bras in your account;'
                                } />
                        } />
                    <BulletedParagraph
                        bulletComponent={<BulletDot />}
                        paragraph={
                            <Paragraph
                                topIsFlush={true}
                                text={
                                    'Digital photos: that you may voluntarily upload to the App to enhance the service;'
                                } />
                        } />
                    <BulletedParagraph
                        bulletComponent={<BulletDot />}
                        paragraph={
                            <Paragraph
                                topIsFlush={true}
                                text={
                                    'Account and subscription information: you aren’t required to create an account or subscribe but if you do, we may have subscription levels that allow you to add more of your bras to your account and other benefits with each level of subscription. If you create an account, we will collect subscription and account information for account administration purposes and to provide you with any applicable subscription. If you subscribe, the App third party payment processor will collect payment information from you and you will need to create and maintain an account with us;'
                                } />
                        } />
                    <BulletedParagraph
                        bulletComponent={<BulletDot />}
                        paragraph={
                            <Paragraph
                                topIsFlush={true}
                                text={
                                    'Mobile app user data: when you access and interact with the App, we may collect certain information about those visits for App operation and use purposes (e.g., to permit your connection to the App, our servers may receive and record information about your device model or other software or hardware information for the purpose of testing, designing and improving our App). The mobile App data includes the following:  user\'s preferred app theme/color scheme, anonymized usage, crash, and performance data, subscription purchase history, email address, and user id, birth year, bras marked as favorites, bras marked as owned/unowned, user\'s canonical bra size, user\'s chest and ribcage measurements for calculating bra size, freeform text entered as "notes" on an individual bra, and breast shape data. You may also voluntarily provide gender assigned at birth but this information is anonymized so that no individual can be identified with it;'
                                } />
                        } />
                    <BulletedParagraph
                        bulletComponent={<BulletDot />}
                        paragraph={
                            <Paragraph
                                topIsFlush={true}
                                text={
                                    'Cookies:  We strive to design the App (both the mobile app and Site) with privacy and security by design in mind.  As part of these efforts, we have limited the type of cookies and similar technologies we utilize. For our Site, we use limited cookies and similar technologies for your operations, analytics, convenience and security. These cookies may collect internet or other electronic network activity including browsing history, search history, and information regarding your interaction with the App and App operations and analytics. Cookies are text files stored on the browser of your computer. Cookies are used to make your experience on our Site more personal and easier to use. You may choose to decline cookies, but doing so may affect your ability to access or use certain features of our App (for more information, see the Cookies and Similar Technology section below); and'
                                } />
                        } />
                    <BulletedParagraph
                        bulletComponent={<BulletDot />}
                        paragraph={
                            <Paragraph
                                topIsFlush={true}
                                text={
                                    'Promotions: when you participate in a promotion or survey through the App, we may collect contact and other information to allow you to participate in the promotion.'
                                } />
                        } />
                </React.Fragment>
                } />

            <Spacer />
            <BulletedParagraph
                bullet={'3.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'How we use your information. ',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                    'We use the information we collect on our App to allow you to use the App and our Services and in the following ways:'
                }
            />
            <BulletedParagraph
                bullet={''}
                paragraph={
                    <React.Fragment>
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to administer your account, if any, and any subscriptions that you may subscribe to;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to enhance the App user experience, to improve our bra calculator algorithm, and offer customized services tailored to you;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to offer individualized bra fit recommendations based on a user’s measurements and bra ratings in combination with aggregate anonymized measurements and ratings from other users;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to run promotions and organize surveys through the App;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to send you important information about the App, our Services, changes to our terms, conditions and policies and other administrative information, including communications regarding your account and subscriptions;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to tell you about subscriptions, services and products that may be of interest to you;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to respond to requests and inquiries from you;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to protect our users, our App and our rights, privacy, safety, and property; to prevent fraud or other inappropriate activity on our App; and to allow us to pursue available remedies or limit the damages that we may sustain;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'when required by law to do so, or to comply with and enforce legal requirements, terms, (including to collect money owed), agreements, and policies; or'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'as necessary for a corporate transaction (such as a merger or acquisition); in any such transfer of information, your personal information would remain subject to this Privacy Policy.'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'we may also aggregate personal information so that the aggregated information does not personally identify you or any other users of the Services, such as for App user statistics and subscription account holder trends.'
                                    } />
                            } />
                    </React.Fragment>
                } />

            <Spacer />
            <BulletedParagraph
                bullet={'4.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'How we share your information.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <MixedStyleParagraph
                topIsFlush={true}
                words={[
                    'Cupbearer does not sell any Personal Information that we collect from users of the App.',
                    ' Below are more details on how we share Personal Information.'
                ]}
                boldIndices={[0]}
                />
            <BulletedParagraph
                bullet={''}
                paragraph={
                    <React.Fragment>
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'with our third-party service providers/contractors who help us operate the App and provide any related services such as for payment processing, App operations, web hosting, and App and Site analytics. Our agreements with these third party service providers require them to have equivalent protections of personal information as those stated in this privacy policy.'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'to third party promotion sponsors, if any. Please read any promotion rules or terms for more details;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'as permitted or required by law, including: (a) to comply with a law, legal process or regulations; (b) responding to or cooperating with law enforcement authorities, other government officials, or other third parties pursuant to a subpoena, a court order or other legal process; (c) to protect the vital interests of a person; (d) to protect our property, terms (including monies owed), agreements, Services, and legal rights; (e) to companies with whom we have agreed to merge with or be acquired by, and (f) to support our audit, compliance, and governance functions, wherein any such disclosure of information, your personal information remains subject to this Privacy Policy;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'when we have obtained your consent to disclose Personal Information; or'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'for any personal information disclosed to third parties, we will only disclose the information that is necessary for them to provide the service or for us to fulfill the request or legal requirements.'
                                    } />
                            } />
                    </React.Fragment>
                } />
            <MixedStyleParagraph
                topIsFlush={true}
                words={
                    [
                        //0
                        'Anonymized Information',
                        //1
                        ': Cupbearer also uses an anonymization feature in certain instances to safeguard the privacy of certain personal information. The anonymization feature is used when sharing aggregate measurements with service providers or partners to provide additional services to you, to improve the App and Services, and when collecting device data regarding usage, performance, and crashes. While we do not sell personal information, in the future, we may share anonymized information that does not identify any individuals with other companies to help provide additional services that may be of interest to you.',
                    ]}
                underlineIndices={[0]}
            />

            <Spacer />
            <BulletedParagraph
                bullet={'5.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'How we protect your information.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                'We use physical, technical, organizational, and administrative safeguards for our App designed to protect against loss, misuse, unauthorized access, disclosure, alteration, or destruction of the information, including any personal or sensitive personal information, we collect through our App. Our security controls are designed to maintain data confidentiality, integrity, and an appropriate level of availability. Cupbearer follows privacy-by-design and security-by-design for its development of our App, Site, and Services. However, we cannot ensure or warrant the security of any information you transmit to our App.'
                } />
            <Paragraph text={
                'Messages that you send to us by email, including by use of the contact us features on the App, may not be secure, and we do not recommend that you use email for sending us confidential or sensitive information.'
            } />
            <Paragraph text={
                'We strongly urge you to be very careful about any personal information you post on the App and on any publicly available postings. DO NOT DISCLOSE ANY SENSITIVE PERSONAL INFORMATION SUCH AS RACE, ETHNICITY, SEXUAL ORIENTATION, HEALTH CONDITIONS, OR PREGNANCY INFORMATION. '
            } />
            <MixedStyleParagraph
                topIsFlush={true}
                words={
                    [
                        //0
                        'Retention/Deletion',
                        //1
                        ': We will use, disclose, delete or retain your personal information only for as long as necessary to fulfill the purposes for which that personal information was collected and as permitted or required by applicable law. Deletion requests will be honored within a reasonable timeframe and as required by applicable law.',
                    ]}
                underlineIndices={[0]}
            />

            <Spacer />
            <BulletedParagraph
                bullet={'6.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'How we use App Data, Cookies, and Similar Technology.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                    'When you use our Mobile App, Site or Services, we may collect certain data as described above related to your use of the App and our Services provided through the App. Your personal information is used to provide App functionality to you.  Analytic usage and performance data is gathered to enable us to support and improve how the App and Services operate.'
                } />
            <Paragraph text={
                'Personal information may also be anonymized and/or aggregated so that you are not identifiable and then used in our research, along with the anonymized, aggregated data of other unidentifiable users, to improve our algorithms over time so we can provide you the best and most accurate recommendations and sizing information. This data is collected when you use our App, but we have in place privacy protective practices so that we only receive the minimum necessary data to provide the App and Services to you.'
            } />
            <Paragraph text={
                'For our Site users, we may send a cookie to your computer. A cookie is a text file which is stored on your computer or other device and allows us to distinguish you from other users of this Site.  Cookies are used to make your experience on our Site more personal and easier to use.  Session-based cookies last only while your browser is open and are automatically deleted when you close your browser. Persistent cookies last until you or your browser delete them or they expire.'
            } />
            <Paragraph text={
                'We strive to design the App (both the mobile app and Site) with privacy and security by design in mind.  As part of these efforts, we have limited the type of cookies and similar technologies we utilize.  For our Site, we use limited cookies and similar technologies for our operations, analytics, convenience and security. These cookies may collect internet or other electronic network activity including browsing history, search history, and information regarding your interaction with the App and App operations and analytics.'
            } />
            <Paragraph
                text={
                    'We do not use functional or social media cookies.'
                }
            />
            <Paragraph text={
                'Cookies we may use include:'
            } />
            <BulletedParagraph
                bullet={''}
                paragraph={
                    <React.Fragment>
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={[
                                        'Performance',
                                        ': We use our own cookies and/or third-party cookies to see how visitors use our Site, in order to enhance performance and improve our Site and Services, according to best practices. This data is anonymized and/or aggregated so that you are not identified.'
                                    ]}
                                    underlineIndices={[0]}
                                />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={[
                                        //0
                                        'Analytic',
                                        //1
                                        ': These cookies and other technologies allow us to understand how you interact with the Site (e.g., how often you use the website, where you are accessing the website from, the content that you are interacting with). Analytic cookies enable us to support and improve how the website operates. For example, we use Google Analytics cookies to help us measure traffic and usage trends for the website. If you do not allow Analytic Cookies, then we will not know when you have visited the website and we will not be able to monitor its performance. For information on how to opt-out of Google Analytics, please see Google Analytics Opt-out Browser Add-on Download Page (',
                                        //2
                                        'tools.google.com/dlpage/gaoptout/',
                                        //3
                                        ') and Google’s privacy practices.'
                                    ]}
                                    underlineIndices={[0]}
                                    webLinkIndices={[2]}
                                />
                            } />
                    </React.Fragment>
                } />
            <MixedStyleParagraph
                words={[
                    //0
                    'How to manage cookies',
                    //1
                    ': You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. The Help feature on most browsers provides information on how to accept cookies, disable cookies, or to notify you when receiving a new cookie. For more information about cookies and how to block them, please visit ',
                    //2
                    'allaboutcookies.org',
                    //3
                    '.'
                ]}
                underlineIndices={[0]}
                webLinkIndices={[2]}
                boldIndices={[2]}
            />

            <MixedStyleParagraph
                words={[
                    // 0
                    'Email Settings',
                    // 1
                    ': You may set your email options to prevent the automatic downloading of images that may contain technologies that would allow us to know whether you have accessed our email and interacted with the content. For example, web beacons in emails may be blocked by setting your email preferences to “Plain Text Only”.'
                ]}
                underlineIndices={[0]}
            />

            <Spacer />
            <BulletedParagraph
                bullet={'7.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'How we provide links to additional services and other websites.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                    'The App may contain links to other websites. Linked websites are independent from our App and are not governed by this Privacy Policy. Cupbearer does not endorse, review, or have any control over the content, products, or services of linked websites or mobile apps or their privacy policies, and Cupbearer is not responsible for the privacy practices of unaffiliated companies or organizations. Once you leave the App, we strongly encourage you to read the applicable Privacy Policy of the other company or organization. If you decide to access any of the linked websites, please understand you do so at your own risk.'
                } />

            <Spacer />
            <BulletedParagraph
                bullet={'8.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Your Choices and Rights.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                    'Any Personal Information collected by or through our App will be used only for the purpose it was provided as described in this Privacy Policy. Once Personal Information is no longer reasonably necessary for business purposes, we will destroy or archive the Personal Information in accordance with our retention and destruction policies.'
                } />
            <Paragraph text={
                'We also respect your rights related to your Personal Information under applicable laws such as California and other jurisdictions. You may have the right: (1) to be informed about our collection, use, and disclosure of your Personal Information; (2) to know if we maintain your Personal Information, and, if we do, to receive a copy (subject to the rights of others) and to update it if it is inaccurate or out of date; (3) to object to our processing of your Personal Information for certain purposes and, if we are processing your Personal Information based on your consent, to withdraw that consent at any time; (4) to ask us to delete, erase or restrict your Personal Information or transfer to another entity if permissible; (5) to file a complaint with your applicable regulator; and (6) to not be subjected to  any discriminatory treatment for the exercise of your privacy rights.'
            } />
            <MixedStyleParagraph
                words={
                    [
                        'Cupbearer does not sell your Personal Information.',
                    ]}
                boldIndices={[0]} />
            <Paragraph text={
                'You can send us your requests related to your Personal Information while signed into the App and via an in-App request or from your email address that you used to create an account or use the App or Site.  We may require that you verify your identity before exercising your individual rights so it is important that you make your request via the App or from your email address that you used to create an account.  Additionally, please note that these rights are not absolute. For example, your deletion request does not ensure complete deletion or comprehensive removal, as the content or information may be retained subject to applicable law, investigation, government order, or where we have a legitimate interest to retain the information.'
            } />
            <MixedStyleParagraph
                words={
                    [
                        'Shine the Light Law',
                        ': Cupbearer does not share information that identifies you personally with any non-affiliated third parties for their own marketing use without your permission.'
                    ]}
                boldIndices={[0]}
                underlineIndices={[0]}
            />
            <MixedStyleParagraph
                words={
                    [
                        'Global Privacy Controls',
                        ': Global Privacy Controls and requests are sent from your browser to websites you visit indicating you do not want to be tracked or monitored.  If your browser supports it, you can turn on Global Privacy Control to opt out of tracking on our Site.   Our website honors Global Privacy Control signals to the extent we are able to receive and act on these signals.'
                    ]}
                boldIndices={[0]}
                underlineIndices={[0]}
            />

            <Spacer />
            <BulletedParagraph
                bullet={'9.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Marketing Communications.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                    'We only will send marketing and informational communications to you if you have affirmatively indicated you want to receive them by providing your email address or in-App selected the appropriate boxes to confirm your choices. You may opt-out of these mailings, future marketing, and informational materials at any time by contacting us as noted below, by changing your in-App preferences, or indicating you changed your preference or clicking the unsubscribe or opt-out feature at the bottom of emails or other marketing materials.'
                } />

            <Spacer />
            <BulletedParagraph
                bullet={'10.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'International Transfer of Personal Information.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                    'We store information received through or by our App in the United States. If you are providing the information from another country, you agree to this transfer and understand that the information will be transferred, stored, processed, and used in the United States.'
                } />

            <Spacer />
            <BulletedParagraph
                bullet={'11.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Lawful bases of processing.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                    'We rely on one or more of the following legal bases to process personal information:'
                } />
            <BulletedParagraph
                bullet={''}
                paragraph={
                    <React.Fragment>
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'the processing is necessary to perform our contractual obligations in our Terms of Use or other contracts with you;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'the processing is necessary for the purposes of our legitimate interests, such as in improving, personalizing, and developing the App, and maintaining App safety and security as described above;'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'consent is provided by you for a specific purpose (i.e., marketing); or'
                                    } />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <Paragraph
                                    topIsFlush={true}
                                    text={
                                        'the processing is necessary to comply with a legal obligation, a court order, or to exercise or defend legal claims.'
                                    } />
                            } />
                    </React.Fragment>
                }
                />

            <Spacer />
            <BulletedParagraph
                bullet={'12.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Our App is not directed to children.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <MixedStyleParagraph
                topIsFlush={true}
                words={[
                    // 0
                    'Our App is not directed to anyone under the age of thirteen.',
                    // 1
                    ' We do not knowingly collect information from individuals under the age of thirteen. If we obtain actual knowledge that we have collected information from an individual under the age of thirteen, we will promptly destroy that information, unless we are legally obligated to retain it. If you believe we have mistakenly or unintentionally collected information from an individual under the age of thirteen, please contact us as noted below.'
                ]}
                boldIndices={[0]}
            />
            <Paragraph text={
                'For users between the ages of thirteen and seventeen, we only collect personal information after receiving affirmative consent from them.'
            } />

            <Spacer />
            <BulletedParagraph
                bullet={'13.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Updates to this Privacy Policy.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <Paragraph
                topIsFlush={true}
                text={
                    'We may update this Privacy Policy from time to time, so please visit this page periodically and review it for any changes that might affect you. We will notify you of material changes to this Privacy Policy by posting a notice on our home page for a reasonable period of time and changing the “Effective Date” above. Your continued use of the App following the posting of changes will constitute your acceptance of the changed terms.'
                } />

            <Spacer />
            <BulletedParagraph
                bullet={'14.'}
                paragraph={
                    <MixedStyleParagraph
                        topIsFlush={true}
                        words={
                            [
                                'Contact Us.',
                            ]}
                        boldIndices={[0]} />
                }
            />
            <MixedStyleParagraph
                topIsFlush={true}
                words={[
                    //0
                    'Your visit to the App is subject to both this Privacy Policy and our Terms of Use, where applicable. If you have any questions, comments, or concerns regarding this Privacy Policy, please contact us by email at ',
                    //1
                    'privacy@cupbearer.app',
                    //2
                    '.',
                ]}
                emailIndices={[1]}
            />

            <ScrollToTop className="scrollToTop" />
        </Pane>
    );
}

export default PrivacyPolicy;
