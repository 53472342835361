import {
    CookieConsentBanner,
    triggerCookieConsentBanner
} from '@porscheofficial/cookie-consent-banner-react';
import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useEffect,
    useState
} from 'react';
import { useCookies } from 'react-cookie';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import Column from '../atoms/Column';

type Props = PropsWithChildren<{
    doCookieConsentReset: boolean,
    setDoCookieConsentReset: Dispatch<SetStateAction<boolean>>;
}>;

const useStyles = createUseStyles({
    CookieConsent: {
         '--cookie-consent-banner-border-radius-buttons': 0,
        '--cookie-consent-banner-spacings-body-padding-left': '5rem',
        '--cookie-consent-banner-spacings-body-padding-right': '5rem',

        '& a': {
            color: 'white',
        },
        '& a:visited': {
            color: 'white',
        },
    },
    horizontalSpace: {
        width: '2ch',
    },
    narrowHorizontalSpace: {
        width: '1ch',
    }
});

/* This keeps Typescript from yelling that dataLayer doesn't exist on window down below */
declare global {
    interface Window {
        dataLayer?: any;
    }
}

const CookieConsent: React.FC<Props> = (props: Props) => {
    const consentCookieName = "cookies_accepted_categories";
    const [acceptedCategoryState, setAcceptedCategoryState] = useState<string[]>([]);
    const [showBanner, setShowBanner] = useState<boolean>(false);
    const [allCookies, setCookie, removeCookie] = useCookies([consentCookieName]);
    const styleClasses = useStyles();

    useEffect(() => {
        const userHasMadeCookieChoice: boolean = allCookies &&
            allCookies.cookies_accepted_categories &&
            allCookies.cookies_accepted_categories !== null &&
            allCookies.cookies_accepted_categories !== undefined;

        setShowBanner(!userHasMadeCookieChoice);
    }, [allCookies]);


    useEffect(() => {
        if (props.doCookieConsentReset) {
            removeCookie(consentCookieName, {path: '/', domain: '.cupbearer.app'});
            props.setDoCookieConsentReset(false);
        }

    }, [props.doCookieConsentReset]);

    const updateConsent = ({ acceptedCategories }: { acceptedCategories: string[] }) => {
        setAcceptedCategoryState(acceptedCategories);
    };

    return (
        <div>
            {showBanner && (
                <CookieConsentBanner
                    className={styleClasses.CookieConsent}
                    handlePreferencesUpdated={({ acceptedCategories }: { acceptedCategories: string[] }) => {
                        if (Boolean(acceptedCategoryState.includes("analytics"))) {
                            window.dataLayer.push({
                                'event': 'cookie_analytics_yes'
                            });
                        }

                        updateConsent({acceptedCategories});
                    }}
                    handlePreferencesRestored={updateConsent}
                    btnLabelAcceptAndContinue="I Consent"
                    btnLabelSelectAllAndContinue="Consent to All"
                    btnLabelOnlyEssentialAndContinue="I Decline"
                    btnLabelPersistSelectionAndContinue="Consent to Selected Cookies Only"
                    contentSettingsDescription="You can decide which cookies are used
                        by selecting the respective options below."
                    availableCategories={[
                        {
                            description:
                                "These cookies are required for the site to provide " +
                                "basic functionality, such as saving your cookie " +
                                "preferences. (These kinds of cookies can't be turned" +
                                " off because the site would cease to function properly.)",
                            key: "functional",
                            label: "Strictly functional cookies",
                            isMandatory: true,
                        },
                        {
                            description:
                                "Let us know how you interact with our site so we can improve the user experience.",
                            key: "analytics",
                            label: "Analytic cookies",
                        },
                    ]}
                >
                    <Column>
                        <span>We use analytic cookies and similar technologies to enhance your
                            experience.  By clicking <b>&quot;I Consent&quot;,</b>{` `}
                            you declare your consent to the use of these cookies.{` `}
                            If you choose <b>&quot;I Decline&quot;,</b> we will{` `}
                            only use the cookies that are strictly necessary for{` `}
                            the site to function. For further information, refer to our{` `}
                            <Link to="/privacy"><b>Privacy Policy</b></Link>
                            {` `}or to open detailed settings{` `}
                            <button
                                onClick={() => {
                                    triggerCookieConsentBanner({ showDetails: true });
                                }}
                                onKeyPress={() => {
                                    triggerCookieConsentBanner({ showDetails: true });
                                }}
                                type="button"
                            >
                               Click Here
                            </button>
                        </span>
                    </Column>
            </CookieConsentBanner>
            )}
        </div>
    );
}

export default CookieConsent;
