import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    url: string;
    textToDisplay?: string;
}>;

const WebLink: React.FC<Props> = (props: Props) => {

    const makeHrefUrl = (providedUrl: string) => {
        const startsWithHttps = providedUrl.startsWith('https://');
        if (startsWithHttps) {
            return providedUrl;
        } else {
            return `https://${providedUrl}`;
        }
    }
    return (
            <a className="webLink" href={makeHrefUrl(props.url)}>
                {props.textToDisplay ? props.textToDisplay : props.url}
            </a>
    );
};

export default WebLink;
