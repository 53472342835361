import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import Column from '../atoms/Column';

type Props = PropsWithChildren<{}>;

const useStyles = createUseStyles({
    container: {
        marginTop: '10vh',
        marginBottom: '15vh',
    },
    number: {
        fontSize: '5rem',
    },
    description: {
        fontSize: '1.5rem',
    }
});

const NotFound: React.FC<Props> = (props: Props) => {
    const styleClasses = useStyles();

    return (
        <Column className={styleClasses.container}>
            <span className={styleClasses.number}>404</span>
            <span className={styleClasses.description}>We can't find that page</span>
        </Column>
    );
}

export default NotFound;
