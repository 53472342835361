import React from 'react';

import Bold from './Bold';
import EmailLink from './EmailLink';
import Plain from './Plain';
import Underline from './Underline';
import WebLink from './WebLink';

/**
 * All the text should be passed as strings to the words array. Any bold text
 * should be separate in the array. Then boldIndices should contain the index
 * numbers of all the text to be bolded.
 */
type Props = {
    style?: React.CSSProperties,
    words: string[],
    boldIndices?: number[],
    emailIndices?: number[],
    underlineIndices?: number[],
    webLinkIndices?: number[],
    topIsFlush?: boolean,
};

const MixedStyleParagraph: React.FC<Props> = (props: Props) => {
    return (
        <p className="mixedParagraph" style={props.topIsFlush ? {marginTop: 0} : {}}>
            {props.words.map((text: string, idx: number) => {
                let componentizedText = null;

                // Check to see if it's a link
                if (props.emailIndices && props.emailIndices.some(emailIdx => idx === emailIdx)) {
                    componentizedText = <EmailLink key={idx} email={text} />
                }  else if (props.webLinkIndices && props.webLinkIndices.some(linkIdx => idx === linkIdx)) {
                    componentizedText = <WebLink key={idx}url={text} />
                }

                // Check if it should be underlined
                if (props.underlineIndices && props.underlineIndices.some(underlineIdx => idx === underlineIdx)) {
                    if (componentizedText === null) {
                        componentizedText = <Underline key={idx}>{text}</Underline>
                    } else {
                        componentizedText = <Underline key={idx}>{componentizedText}</Underline>
                    }
                }

                // Check if it should be bold
                if (props.boldIndices && props.boldIndices.some(boldIdx => idx === boldIdx)) {
                    if (componentizedText === null) {
                        componentizedText = <Bold key={idx}>{text}</Bold>
                    } else {
                        componentizedText = <Bold key={idx}>{componentizedText}</Bold>
                    }
                }

                // If it hasn't been styled yet, make it plain
                if (componentizedText === null) {
                    componentizedText = <Plain key={idx}>{text}</Plain>
                }

                return componentizedText;
            })}
        </p>
    );
};

export default MixedStyleParagraph;
