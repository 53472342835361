import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

type Props = PropsWithChildren<{
    className?: string,
}>;

const useStyles = createUseStyles({
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const Column: React.FC<Props> = (props: Props) => {
    const styleClasses = useStyles();

    return (
        <div className={`${styleClasses.column}${props.className ? ' ' + props.className : ''}`}>
            {props.children}
        </div>
    );
}

export default Column;
