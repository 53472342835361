import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import Pane from '../atoms/Pane';
import BulletDot from '../stylisticComponents/BulletDot';
import BulletedParagraph from '../stylisticComponents/BulletedParagraph';
import MixedStyleParagraph from '../stylisticComponents/MixedStyleParagraph';
import Paragraph from '../stylisticComponents/Paragraph';
import Spacer from '../stylisticComponents/Spacer';

type Props = PropsWithChildren<{}>;

const useStyles = createUseStyles({
    innerPane: {
        textAlign: 'left',
    },
    bigHeader: {
        textAlign: 'center',
        fontSize: '2rem',
    },
    mediumHeader: {
        fontSize: '1.5rem'
    }
});


const DeletionInstructions: React.FC<Props> = (props: Props) => {
    const styleClasses = useStyles();

    return (
        <Pane className={styleClasses.innerPane}>
            <h1 className={styleClasses.bigHeader}>Deleting Your Data</h1>
            <h2 className={styleClasses.mediumHeader}>Data Deletion Without Account Deletion</h2>
            <Paragraph text={'If you want to keep your account but just want to delete ' +
                'data you\'ve added, you can do that from within the app.'} />
            <BulletedParagraph
                bullet={''}
                paragraph={
                    <React.Fragment>
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                            <MixedStyleParagraph
                                topIsFlush={true}
                                words={[
                                    'Bras: ',
                                    'To delete a bra, tap it in the ',
                                    'All Bras',
                                    ' list to enter the detail view, then tap the garbage can icon in the top right corner next to the menu icon, and tap ',
                                    '"Delete"',
                                    ' in the window that pops up.'
                                ]}
                                boldIndices={[0, 2, 4]}
                            />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={[
                                        'Current Bra Photos: ',
                                        'To delete a bra photo, tap the bra in the ',
                                        'All Bras',
                                        ' list to enter the detail view, then tap the pencil icon in the top right corner of the photo, and tap ',
                                        '"Delete"',
                                        ' in the window that pops up.'
                                    ]}
                                    boldIndices={[0, 2, 4]}
                                />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <MixedStyleParagraph
                                    topIsFlush={true}
                                    words={[
                                        'Past Bra Photos: ',
                                        'To delete a previous bra photo, tap the bra in the ',
                                        'All Bras',
                                        ' list to enter the detail view, then scroll through the ',
                                        'History',
                                        ' tab at the bottom until you find the thumbnail of the photo you want to delete.  Put your finger on the thumbnail photo and hold it there until a window pops up, then tap  ',
                                        '"Delete"',
                                        '.'
                                    ]}
                                    boldIndices={[0, 2, 4, 6]}
                                />
                            } />
                        <BulletedParagraph
                            bulletComponent={<BulletDot />}
                            paragraph={
                                <React.Fragment>
                                    <MixedStyleParagraph
                                        topIsFlush={true}
                                        words={[
                                            'Custom Tags: ',
                                            'To remove a tag from a bra, you can simply enter its ',
                                            'Bra Detail',
                                            ' screen, tap the pencil in the upper right of the ',
                                            'Tags',
                                            ' section, tap the tag you want to remove in order to unselect it, then tap ',
                                            '"Done"',
                                            ' at the bottom to save your changes',
                                            '.'
                                        ]}
                                        boldIndices={[0, 2, 4, 6]}
                                    />
                                    <MixedStyleParagraph
                                        words={[
                                            'If instead you want to delete a tag entirely and remove it from all bras it\'s currently applied to, you can do that by clicking the icon in the top right corner of the screen to enter the ',
                                            'Settings',
                                            ' menu, choosing ',
                                            '"Manage Data"',
                                            ' from the ',
                                            '"Account"',
                                            ' section at the top of the menu, choosing ',
                                            '"Delete a Custom Tag"',
                                            ', selecting the tag you wish to delete, and tapping ',
                                            '"Delete This Tag"',
                                            ' from the window that pops up'
                                        ]}
                                        boldIndices={[1,3,5,7,9]}
                                        />
                                </React.Fragment>
                            }
                        />
                   </React.Fragment>
               }
            />
            <Paragraph text={'The data you delete in the app will be removed from your device immediately but may be kept in temporary storage on our servers for up to 30 days before being permanently deleted.'} />
            <Spacer />

            <h2 className={styleClasses.mediumHeader}>Account Deletion</h2>
            <Paragraph text={'You can request deletion of your account and all your data ' +
                'from within the Cupbearer app by:'} />

            <BulletedParagraph bullet={''}
                               paragraph={
                <React.Fragment>
                    <BulletedParagraph bulletComponent={'1'}
                                       paragraph={
                                           <MixedStyleParagraph
                                               topIsFlush={true}
                                               words={[
                                                   'Entering the ',
                                                   'Settings',
                                                   ' menu by clicking the icon in the top right corner'
                                               ]}
                                               boldIndices={[1]}
                                           />
                                       } />
                    <BulletedParagraph bulletComponent={'2'}
                                       paragraph={
                                           <MixedStyleParagraph
                                               topIsFlush={true}
                                               words={[
                                                   //0
                                                   'Choosing ',
                                                   //1
                                                   '"Manage Data"',
                                                   //2
                                                   ' from the ',
                                                   //3
                                                   '"Account"',
                                                   //4
                                                   ' section at the top of the menu'
                                               ]}
                                               boldIndices={[1, 3]}
                                           />
                                       } />
                    <BulletedParagraph bulletComponent={'3'}
                                       paragraph={
                                           <MixedStyleParagraph
                                               topIsFlush={true}
                                               words={[
                                                   'Choosing ',
                                                   '"Delete This Account and All My Data"'
                                               ]}
                                               boldIndices={[1]}
                                           />
                                       } />
                    <BulletedParagraph bulletComponent={'4'}
                                       paragraph={'Confirming your choice in the box that pops up'} />
                               </React.Fragment>
            } />

            <MixedStyleParagraph
                words={
                [
                    // 0
                    'If you registered your account with an email address, you can also contact us at ',
                    // 1
                    'deletion@cupbearer.app',
                    // 2
                    ' from that email address to make a deletion request.'
                ]}
                emailIndices={[1]}
            />

            <Paragraph
                text={'Either of these methods will initiate the deletion process ' +
                    'for all the data we have for you, including any bras, photos,' +
                    ' or tags you\'ve added, in accordance with the terms lined' +
                    ' out in our Privacy Policy.'}
            />

            <Paragraph text={'We will need to verify your identity before removing your data, ' +
                'which is why it\'s important you make your request from within the App or using the email' +
                ' that you used to create an account.'} />
            <Paragraph text={'Additionally, please note that while we will make every ' +
                'effort to delete all your data shortly after receiving your request,' +
                ' there are circumstances where this may not be possible, such as if ' +
                'we are required to keep it due to local laws or a government order.'} />
            <MixedStyleParagraph
                words={[
                    //0
                    'Please review our Privacy Policy at ',
                    //1
                    'www.cupbearer.app/privacy',
                    //2
                    ' for complete details'
                ]}
                webLinkIndices={[1]}
                />


        </Pane>
    );
}

export default DeletionInstructions;
