import React, { PropsWithChildren } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import Pane from '../atoms/Pane';
import { useScreenSize } from '../organisms/PageWrapper';

type Props = PropsWithChildren<{}>;

const GoogleForm: React.FC<Props> = (props: Props) => {
    const { isTablet, isMobile } = useScreenSize();

    return (
        <Pane>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScitxaNxlFO1jjuXFkMLK8rxPc3hCs2yKoYs2faSQYAWd22XA/viewform?embedded=true"
                    width={isTablet ? "320" : isMobile ? "280" : "640"}
                    height="1020"
                    frameBorder="0"
            >Loading…</iframe>
            <ScrollToTop className="scrollToTop" />
        </Pane>
    );
}

export default GoogleForm;
