import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
    email: string
}>;

const EmailLink: React.FC<Props> = (props: Props) => {
    return (
        <a className="weblink" href={`mailto:${props.email}`}>{props.email}</a>
    );
};

export default EmailLink;
