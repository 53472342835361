import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';
import { ReactComponent as Logo3 } from '../assets/wordmarkWithSlogan.svg';

type Props = PropsWithChildren<{
    isMobile?: boolean,
    isTablet?: boolean,
}>;

const useStyles = createUseStyles({
    wordmark: {
        fill: '#fff',
        stroke: '#fff',
        height: '100%',
        width: '100%',
        objectFit: 'contain',
    },
    container: {
        display: 'inline-block',
        width: 'auto',
        height: '12vw',
    },
});

const WordmarkWithSlogan: React.FC<Props> = (props: Props) => {
    const styleClasses = useStyles();

    return (
        <div className={styleClasses.container} style={props.isMobile ? {height: '20vw'} : props.isTablet ? {height: '16vw'} : {}}>
            <Logo3 className={styleClasses.wordmark} />
        </div>
    );
}

export default WordmarkWithSlogan;
