import React from 'react';
import Bold from './Bold';
import Plain from './Plain';

/**
 * All the text should be passed as strings to the words array. Any bold text
 * should be separate in the array. Then boldIndices should contain the index
 * numbers of all the text to be bolded.
 */
type Props = {
    isBold?: boolean;
    text: string;
    topIsFlush?: boolean;
};

const Paragraph: React.FC<Props> = (props : Props) => {
    return (
        <p className="paragraph" style={props.topIsFlush ? {marginTop: 0} : {}}>
            {props.isBold ? <Bold>{props.text}</Bold> : <Plain>{props.text}</Plain>}
        </p>
    );
}

export default Paragraph;
