import React, { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

type Props = PropsWithChildren<{
    style?: React.CSSProperties,
    useDropShadow?: boolean,
    alignLeft?: boolean,
    noPadding?: boolean,
    className?: string,
}>;

const startColor = 'rgba(0,0,0,.25)';
const endColor = 'rgba(0,0,0,0)';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    pane: {
        backgroundColor: 'rgba(256, 256, 256, .3)',
        padding: '2ch',
        maxWidth: '75ch',
        marginTop: '1rem',
        marginBottom: '1rem',
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
    },
    shadow: {
        height: '5px',
        backgroundImage: `linear-gradient(${startColor},${endColor})`,
        marginBottom: '1rem',
    },
});

const Pane: React.FC<Props> = (props: Props) => {

    const styleClasses = useStyles();

    const mergedPaneStyles: React.CSSProperties = {
        ...(props.useDropShadow ? { marginBottom: 0 } : {}),
        ...(props.alignLeft ? { textAlign: 'left' as 'left', } : {}),
        ...(props.noPadding ? { padding: 0 } : {}),
    };

    return (
        <div className={`${styleClasses.container}, ${props.className}`}>
            <div className={styleClasses.pane} style={mergedPaneStyles} >
                {props.children}
            </div>
            {props.useDropShadow && (
                    <div className={styleClasses.shadow} />
            )}
        </div>
    );
}

export default Pane;
